import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PortableText } from "@portabletext/react";
import imageUrlBuilder from "@sanity/image-url";
import { fetchSinglePost } from "../../utils/fetchNotes";
import client from "../../utils/sanity";
import CodeBlock from "../../components/CodeBlock";
import NoteEntry from "../../components/NoteEntry";
import Accordion from "../../components/Accordion";
import {
  PortableTextReactComponents,
  PortableTextComponentProps,
} from "@portabletext/react";

// Utility function to generate image URLs
const builder = imageUrlBuilder(client);
function urlFor(source, width) {
  return builder.image(source).width(width).url();
}

// Components for PortableText to render rich text from Sanity
const ptComponents: Partial<PortableTextReactComponents> = {
  types: {
    image: ({ value }: { value: { asset: { _ref: string }; alt: string } }) => {
      const [isEnlarged, setIsEnlarged] = useState(false);
      const toggleEnlarge = () => setIsEnlarged(!isEnlarged);

      const src = urlFor(value.asset._ref, 800);
      const srcSet = `
        ${urlFor(value.asset._ref, 320)} 320w,
        ${urlFor(value.asset._ref, 480)} 480w,
        ${urlFor(value.asset._ref, 800)} 800w,
        ${urlFor(value.asset._ref, 1200)} 1200w
      `;

      return (
        <div
          className={`image-container ${isEnlarged ? "enlarged" : ""}`}
          onClick={toggleEnlarge}
        >
          <img
            alt={value.alt || " "}
            loading="lazy"
            src={src}
            srcSet={srcSet}
            sizes="100vw"
          />
          <div className="enlarge-text">
            {isEnlarged ? "Reduce Image" : "Enlarge Image"}
          </div>
        </div>
      );
    },
    code: (props) => <CodeBlock props={props.value} />,
    accordion: ({ value }) => (
      <Accordion title={value.title} content={value.content} />
    ),
    separator: ({ value }) => (
      <hr
        className={`separator ${value.style === "thick" ? "thick" : "default"}`}
      />
    ),
  },
  block: {
    h1: ({ children }) => <h1>{children}</h1>,
    h2: ({ children }) => <h2>{children}</h2>,
    h3: ({ children }) => <h3>{children}</h3>,
    blockquote: ({ children }) => (
      <blockquote className="styled-quote">{children}</blockquote>
    ),
    normal: ({ children }) => <p>{children}</p>,
    noMargin: ({ children }) => <p className="no-margin">{children}</p>,
    serif: ({ children }) => <p className="serif">{children}</p>,
  },
  list: {
    bullet: ({ children }) => <ul>{children}</ul>,
    spaciousBullet: ({ children }) => (
      <ul className="spacious-bullet">{children}</ul>
    ),
  },
  listItem: {
    bullet: ({ children }) => <li>{children}</li>,
    spaciousBullet: ({ children }) => (
      <li className="spacious-bullet-item">{children}</li>
    ),
  },
};

interface NoteType {
  title: string;
  categories: string[];
  body: any;
  publishedAt: string;
  slug?: { current: string };
}

const Note = () => {
  const { slug } = useParams<{ slug: string }>();
  const [note, setNote] = useState<NoteType | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function loadNote() {
      if (slug) {
        try {
          const data = await fetchSinglePost(slug);
          if (data) {
            const dateObj = new Date(data.publishedAt);
            const formattedDate = `${dateObj.getDate().toString().padStart(2, "0")}/${(dateObj.getMonth() + 1).toString().padStart(2, "0")}/${dateObj.getFullYear()}`;
            setNote({ ...data, date: formattedDate });
          }
        } catch (error) {
          console.error("Failed to fetch note:", error);
        } finally {
          setLoading(false);
        }
      }
    }

    loadNote();
  }, [slug]);

  if (loading) {
    return (
      <div className="main-wrapper">
        <div className="notes-wrapper">Loading note...</div>
      </div>
    );
  }

  if (!note) {
    return (
      <div className="main-wrapper">
        <div className="notes-wrapper">Note not found.</div>
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <div className="notes-wrapper">
        <NoteEntry
          key={note.slug?.current}
          title={note.title}
          slug={note.slug?.current || ""}
          date={note.date}
          topics={note.categories}
        />
        <div className="note-wrapper">
          <article className="note-body">
            <PortableText value={note.body} components={ptComponents} />
          </article>
        </div>
      </div>
    </div>
  );
};

export default Note;
