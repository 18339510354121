// src/AppRouter.js
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Homepage";
import About from "./pages/About";
import Note from "./pages/note/Note";
import TopicsPage from "./pages/topics/Topics";

const AppRouter = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={<Home title="Welcome to our future Homepage." />}
      />
      <Route path="/about" element={<About />} />
      <Route path="/:slug" element={<Note />} />
      <Route path="/topics/:topic" element={<Home title="Filtered Notes" />} />
      <Route path="/topics" element={<TopicsPage />} />
    </Routes>
  );
};

export default AppRouter;
