// src/components/NoteEntry.tsx
import { Link } from "react-router-dom";

interface NoteEntryProps {
  key: string;
  title: string;
  slug: string;
  date: string;
  topics: string[];
}

const NoteEntry = ({ title, slug, date, topics }: NoteEntryProps) => {
  return (
    <div className="note-container">
      <div className="note-container-info">
        <Link to={`/${slug}`} className="note-link">
          ❧ {title}
        </Link>
      </div>
      <div className="note-container-meta">
        <span className="note-date">{date}</span> ⇨
        {topics.map((topic, index) => (
          <Link key={index} to={`/topics/${topic}`} className="note-tag">
            {topic}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NoteEntry;
