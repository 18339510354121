// src/utils/fetchNotes.js
import client from "./sanity";
import groq from "groq";

const CACHE_KEY_PREFIX = "fidacuraCache_";
const CACHE_DURATION = 60 * 60 * 1000; // 1 hour in milliseconds

function isCacheValid(timestamp) {
  const now = Date.now();
  return now - timestamp < CACHE_DURATION;
}

function getCacheItem(key) {
  const cachedData = localStorage.getItem(CACHE_KEY_PREFIX + key);
  if (cachedData) {
    const { data, timestamp } = JSON.parse(cachedData);
    if (isCacheValid(timestamp)) {
      return data;
    }
  }
  return null;
}

function setCacheItem(key, data) {
  localStorage.setItem(
    CACHE_KEY_PREFIX + key,
    JSON.stringify({ data, timestamp: Date.now() })
  );
}

export async function fetchAllPosts() {
  const cachedPosts = getCacheItem("allPosts");
  if (cachedPosts) {
    return cachedPosts;
  }

  const posts = await client.fetch(
    groq`
      *[_type == 'post' && publishedAt < now()] | order(publishedAt desc) {
        _id,
        title,
        slug,
        publishedAt,
        "categories": categories[]->title,
        pinned
      }
    `
  );

  setCacheItem("allPosts", posts);
  return posts;
}

export async function fetchSinglePost(slug) {
  const cacheKey = `post_${slug}`;
  const cachedPost = getCacheItem(cacheKey);
  if (cachedPost) {
    return cachedPost;
  }

  const post = await client.fetch(
    groq`
      *[_type == "post" && slug.current == $slug][0]{
        title,
        slug,
        "categories": categories[]->title,
        body,
        publishedAt
      }
    `,
    { slug }
  );

  if (post) {
    setCacheItem(cacheKey, post);
  }
  return post;
}

export async function fetchPostsByTopic(topic) {
  const cacheKey = `topic_${topic}`;
  const cachedPosts = getCacheItem(cacheKey);
  if (cachedPosts) {
    return cachedPosts;
  }

  const posts = await client.fetch(
    groq`
      *[_type == "post" && defined(publishedAt) && publishedAt < now() && publishedAt > '1970-01-02T00:00:00Z' && $topic in categories[]->title] | order(publishedAt desc) {
        _id,
        title,
        slug,
        publishedAt,
        "categories": categories[]->title,
        pinned
      }
    `,
    { topic }
  );

  console.log(`Fetched posts for topic "${topic}":`, posts);

  setCacheItem(cacheKey, posts);
  return posts;
}

export async function fetchAllTopics() {
  const cacheKey = "allTopics";
  const cachedTopics = getCacheItem(cacheKey);
  if (cachedTopics) {
    return cachedTopics;
  }

  const query = `*[_type == "post" && publishedAt < now() && defined(categories)] {
    "categories": categories[]->title
  }`;
  const result = await client.fetch(query);
  const allTopics = result.flatMap((post) => post.categories);
  const uniqueTopics = [...new Set(allTopics)];
  const sortedTopics = uniqueTopics.sort();

  setCacheItem(cacheKey, sortedTopics);
  return sortedTopics;
}
