// src/componentes/CodeBlock.tsx
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialDark } from "react-syntax-highlighter/dist/cjs/styles/prism/";

const CodeBlock = ({ props }) => {
  const { code, language } = props;
  // if (!props || !props.code) {
  //   return null;
  // }

  return (
    <pre>
      <SyntaxHighlighter
        children={code}
        language="javascript"
        style={materialDark}
        className="code"
      ></SyntaxHighlighter>
    </pre>
  );
};

export default CodeBlock;
