// src/components/Navbar.tsx
import { Link } from "react-router-dom";
import ThemeSwitcher from "./ThemeSwitcher";

const Navbar = () => {
  return (
    <nav>
      <div className="notebook-nav">
        <div className="notebook-nav-left">
          <div className="notebook-title">
            <Link to="/">
              <span className="notebook-icon"></span> notes.fidacura.xyz
            </Link>
          </div>
        </div>
        <div className="notebook-nav-right">
          <a href="/rss.xml" target="_blank" rel="noopener noreferrer">
            <button>RSS</button>
          </a>
          <ThemeSwitcher />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
