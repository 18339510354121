// src/components/Accordion.tsx
import React, { useState } from "react";

const Accordion = ({ title, content }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion">
      <div className="accordion-header" onClick={toggleAccordion}>
        <h3>{title}</h3>
        <span>{isOpen ? "▼" : "▶"}</span>
      </div>
      {isOpen && (
        <div className="accordion-content" onClick={toggleAccordion}>
          <pre>{content}</pre>
        </div>
      )}
      <style jsx>{`
        .accordion {
          border: 1px solid #ddd;
          border-radius: 4px;
          margin-bottom: 10px;
        }
        .accordion-header {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          cursor: pointer;
          background: #f7f7f7;
        }
        .accordion-content {
          padding: 10px;
        }
      `}</style>
    </div>
  );
};

export default Accordion;
