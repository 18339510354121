// src/components/Contacts.tsx
import React from "react";

const Contacts = () => {
  return (
    // <div className="main-wrapper">
    <div className="contacts">
      <div className="contact-item">
        <a href="mailto:hello@fidacura.xyz" target="_blank" className="contact">
          Email
        </a>
      </div>
      <div>
        <a
          href="https://www.are.na/fidacura/"
          target="_blank"
          className="contact"
        >
          Are.na
        </a>
      </div>
      <div>
        <a
          href="https://github.com/fidacura/"
          target="_blank"
          className="contact"
        >
          GitHub
        </a>
      </div>
      <div>
        <a
          href="https://goodreads.com/fidacura/"
          target="_blank"
          className="contact hide-mobile"
        >
          GoodReads
        </a>
      </div>
    </div>
    // </div>
  );
};

export default Contacts;
