// src/pages/About.js

const About = () => {
  return (
    <div className="root-wrapper">
      <p>This is the about page of our application.</p>
    </div>
  );
};

export default About;
