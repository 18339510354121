// src/pages/Homepage.tsx
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import NoteEntry from "../components/NoteEntry";
import { fetchAllPosts, fetchPostsByTopic } from "../utils/fetchNotes";
import Contacts from "../components/Contacts";

interface HomepageProps {
  title: string;
}

interface NoteType {
  _id: string;
  title: string;
  slug: { current: string };
  publishedAt: string;
  categories: string[];
}

const Homepage = ({ title }: HomepageProps) => {
  const { topic } = useParams<{ topic?: string }>();
  const [notes, setNotes] = useState<NoteType[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function getNotes() {
      try {
        let postsData;
        if (topic) {
          postsData = await fetchPostsByTopic(topic);
        } else {
          postsData = await fetchAllPosts();
        }
        setNotes(postsData);
      } catch (error) {
        console.error("Failed to fetch notes:", error);
      } finally {
        setLoading(false);
      }
    }

    getNotes();
  }, [topic]);

  return (
    <div className="main-wrapper">
      <div className="">
        <div className="notebook-container fidacura">
          <div className="notebook-container">
            <div className="notes-wrapper">
              {loading ? (
                <div>Loading...</div>
              ) : (
                notes.map((note) => (
                  <NoteEntry
                    key={note._id}
                    title={note.title}
                    slug={note.slug.current}
                    date={new Date(note.publishedAt).toLocaleDateString()}
                    topics={note.categories}
                  />
                ))
              )}
            </div>
          </div>
        </div>
        <Contacts />
      </div>
    </div>
  );
};

export default Homepage;
