// src/pages/topics/Topics.tsx
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { fetchAllTopics } from "../../utils/fetchNotes";

const TopicsPage = () => {
  const [topics, setTopics] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const loadTopics = async () => {
      try {
        const fetchedTopics = await fetchAllTopics();
        setTopics(fetchedTopics);
      } catch (err) {
        console.error("Failed to fetch topics:", err);
        setError("Failed to load topics. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    loadTopics();
  }, []);

  if (loading) {
    return (
      <div className="main-wrapper">
        <div className="notes-wrapper">Loading topics...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="main-wrapper">
        <div className="notes-wrapper">Topics not found.</div>
      </div>
    );
  }

  return (
    <div className="main-wrapper">
      <div className="notes-wrapper">
        <h2>Topics</h2>
        {topics.length > 0 ? (
          <ul className="topics-list">
            {topics.map((topic) => (
              <li key={topic}>
                <Link to={`/topics/${topic}`}>{topic}</Link>
              </li>
            ))}
          </ul>
        ) : (
          <p>No topics found.</p>
        )}
      </div>
    </div>
  );
};

export default TopicsPage;
