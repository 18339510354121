// src/components/ThemeSwitcher.tsx

import { useTheme } from "../context/ThemeContext";

const ThemeSwitcher = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button className="theme-btn" onClick={toggleTheme}>
      {theme === "light" ? "Light" : "Dark"}
    </button>
  );
};

export default ThemeSwitcher;
